import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
//components
import CardButton from "components/Card/CardButton"
import { FlexCenter } from "components/CssTools"
import Footer from "components/Footer"
import Layout from "components/Layout"
import { Card, StyledLogo } from "components/secondpage.style"
import SlideHeader from "components/SlideHeader"
import { media } from "styles/media"
import theme from "styles/theme"
import FadeEffect from "components/ui/FadeEffect"
import { ENTERED } from "react-transition-group/Transition"
import TabletHeader from "components/TabletHeader"
import SEO from '../components/SEO'

const StyledImage = styled.div`
  position: relative;
  ${FlexCenter}
  flex-flow:column nowrap;
  width: 343px;
  height: 250px;
  .cardVis {
    border-radius: 16px 16px 0 0;
    height: 215px;
    position: absolute;
    top: 0;
    left: 0;
  }
`
const StyledCards = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
`

const setInitial = entry => {
  if (entry.state.prevPage?.endsWith("/labo/")) {
    return { x: entry.state.x }
  }
  if (entry.state.prevPage?.endsWith("/bits-of-knowledge/")) {
    return { x: entry.state.x }
  }

  return { x: 0, opacity: 0 }
}

const setAnimation = (transitionStatus, exit) => {
  if (transitionStatus === "exiting") {
    if (exit.state.nextPage === "labo") {
      return { x: -exit.state.x }
    }
    if (exit.state.nextPage === "bitsOfKnowledge") {
      return { x: -exit.state.x }
    }
  }
  return { x: 0, opacity: 1 }
}

const setTransition = entry => {
  if (Object.keys(entry.state).length > 0) {
    return { ease: "easeOut", duration: entry.length }
  }

  if (Object.keys(entry.state).length === 0) {
    return { ease: "easeOut", duration: 0.6 }
  }
}

const ThirtyYears = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, exit, entry } = props
  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.thirtyYers.pass}`,
          "name": `${data.seo.page.thirtyYers.title}`,
        }
      }]
    }
  ]

  return (
    <Layout>
      <SEO
        title={data.seo.page.thirtyYers.title}
        pageUrl={data.seo.page.thirtyYers.pass}
        description={data.seo.page.thirtyYers.description}
        imgUrl='/mainVisual.jpg'
        type='article'
        jsonld={jsonld}
      />
      <SlideHeader location={location} entry={entry} />
      {/* <FadeEffect> */}
      <TabletHeader location={location} />
      <motion.div
        initial={setInitial(entry)}
        animate={setAnimation(transitionStatus, exit)}
        transition={setTransition(entry)}
      >
        <StyledLogo>
          <StaticImage
            src="../images/logo-francois.svg"
            alt="フランソア"
            className="leftImg"
            placeholder="none"
          />
          <StaticImage
            src="../images/logo30.svg"
            alt="食物繊維と30年"
            placeholder="none"
          />
          <p>{data.lead.thirtyYearsCard.leadText}</p>
          <StaticImage
            src="../images/logo-soygood.svg"
            alt="フランソア"
            className="rightImg2"
            placeholder="none"
            quality={100}
          />
        </StyledLogo>
        <StyledCards>
          <Card bgColor={theme.colors.primary.gray}>
            <StyledImage>
              <StaticImage
                src="../images/logo-francois.svg"
                alt="フランソア"
                className=""
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.ourCommitment.leadText}</p>
            <CardButton
              link="/thirty-years/our-commitment/"
              sdColor={theme.colors.shadow.gray}
            />
          </Card>
          <Card bgColor={theme.colors.primary.gray}>
            <StyledImage>
              <StaticImage
                src="../images/farmImage.jpg"
                alt="大豆畑"
                className="cardVis"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.focusOnFiber.leadText}</p>
            <CardButton
              link="/thirty-years/focus-on-fiber"
              sdColor={theme.colors.shadow.gray}
            />
          </Card>
          <Card bgColor={theme.colors.primary.gray}>
            <StyledImage>
              <StaticImage
                src="../images/pasteImage.jpg"
                alt="ペーストの大豆"
                className="cardVis"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.tofuRefuse.leadText}</p>
            <CardButton
              link="/thirty-years/tofu-refuse"
              sdColor={theme.colors.shadow.gray}
            />
          </Card>
          <Card bgColor={theme.colors.primary.gray}>
            <StyledImage>
              <StaticImage
                src="../images/farmGreen.jpg"
                alt="チコリの畑"
                className="cardVis"
                placeholder="none"
              />
            </StyledImage>
            <p>{data.text.meetInuria.leadText}</p>
            <CardButton
              link="/thirty-years/meet-inuria/"
              sdColor={theme.colors.shadow.gray}
            />
          </Card>
        </StyledCards>
        <Footer />
      </motion.div>
      {/* </FadeEffect> */}
    </Layout>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "thirtyYearsPage" }) {
      ourCommitment {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      focusOnFiber {
        leadText
        firstBlock {
          paragraph1
          paragraph2
          paragraph3
        }
        secondBlock {
          leadText
          paragraph1
          paragraph2
        }
        imgtxt
      }
      tofuRefuse {
        leadText
        firstBlock {
          paragraph1
          paragraph2
          paragraph3
        }
        secondBlock {
          paragraph1
          paragraph2
        }
        imgtxt
      }
      meetInuria {
        firstBlock {
          paragraph1
          paragraph2
          paragraph3
        }
        leadText
        secondBlock {
          leadText
          paragraph1
          paragraph2
        }
        imgtxt
      }
    }
    lead: componentsYaml {
      laboCard {
        leadText
      }
      bitsOfKnowledgeCard {
        leadText
      }
      thirtyYearsCard {
        leadText
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        thirtyYers {
          description
          pass
          title
        }
      }
    }
  }
`

export default ThirtyYears
